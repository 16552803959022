<template>
    <div class="maintenance-table">
        <table>
            <thead>
                <tr>
                    <th>序号</th>
                    <th>单位</th>
                    <th>设备</th>
                    <th>姓名</th>
                    <th>联系电话</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in tableData" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.company }}</td>
                    <td>{{ item.equipment }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.phoneNumber }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [
                { "id": 1, "company": "卡特彼勒", "equipment": "发电机、高压油机、供油泵、潜水泵", "name": "吴飞军", "phoneNumber": "138*****513" },
                { "id": 2, "company": "ABB", "equipment": "高低压、配电箱", "name": "张允勇", "phoneNumber": "134*****501" },
                { "id": 3, "company": "华为", "equipment": "华为UPS、精密空调", "name": "奉工", "phoneNumber": "156*****671" },
                { "id": 4, "company": "理士", "equipment": "理世电池", "name": "欧阳", "phoneNumber": "138*****561" },
                { "id": 5, "company": "易事特", "equipment": "EPS（易事特）", "name": "杨工", "phoneNumber": "139*****981" },
                { "id": 6, "company": "图腾", "equipment": "机柜（图腾）", "name": "徐工", "phoneNumber": "137*****609" },
                { "id": 7, "company": "湖北灵坦", "equipment": "加药装置", "name": "林工", "phoneNumber": "180*****213" },
                { "id": 8, "company": "荏原", "equipment": "水泵", "name": "吴工", "phoneNumber": "135*****302" },
                { "id": 9, "company": "环渤高科", "equipment": "蓄冷罐", "name": "陈工", "phoneNumber": "188*****465" },
                { "id": 10, "company": "马利", "equipment": "冷却塔", "name": "朱工", "phoneNumber": "137*****705" },
                { "id": 11, "company": "广州洛森", "equipment": "消防风机（广州洛森）", "name": "袁工", "phoneNumber": "134*****344" },
                { "id": 12, "company": "海康威视", "equipment": "视频监控系统", "name": "王工", "phoneNumber": "189*****826" },
                { "id": 13, "company": "西门子", "equipment": "BA系统", "name": "陈工", "phoneNumber": "150*****382" },
                { "id": 14, "company": "日本湿王", "equipment": "加湿器", "name": "郭工", "phoneNumber": "138*****438" },
                { "id": 15, "company": "中控", "equipment": "门禁系统", "name": "叶工", "phoneNumber": "189*****399" },
                { "id": 16, "company": "栅格", "equipment": "动环系统", "name": "雷工", "phoneNumber": "180*****770" },
                { "id": 17, "company": "消防", "equipment": "极早期、自动报警、气体灭火系统", "name": "黄楚宏", "phoneNumber": "138*****323" },
                { "id": 18, "company": "风语筑", "equipment": "多媒体、电气（展厅）", "name": "丰维", "phoneNumber": "177*****400" },
                { "id": 19, "company": "格力", "equipment": "冷水机组、在线清洗装置", "name": "冯工", "phoneNumber": "166*****584" }
            ]
        };
    },
    methods: {
        // 如果有需要格式化的内容，可以在这里定义方法
    }
};
</script>

<style scoped>
.maintenance-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-spacing: 0;
    border-collapse: collapse;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

td.left-align {
    text-align: left;
}

@media (max-width: 600px) {
    .maintenance-table th,
    .maintenance-table td {
        display: block;
        width: 100%;
    }

    .maintenance-table td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 50%;
    }
}
</style>