<template>
    <div id="app">
        <el-row>
            <el-col :span="24">
                <el-card shadow="hover">
                    <div slot="header" class="header-content">
                        <span class="custom-font">合作伙伴</span>
                    </div>
                    <MaintenanceTable />
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<style>
/* 自定义字体样式 */
.custom-font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    font-weight: bold;
    /* 加粗 */
    font-size: 24px;
    /* 加大字体 */
    text-align: center;
    /* 居中对齐 */
}

/* header 居中对齐 */
.header-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<script>
import MaintenanceTable from './datalist.vue';

export default {
    components: {
        MaintenanceTable
    }
};
</script>