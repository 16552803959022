var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maintenance-table" }, [
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.tableData, function(item) {
          return _c("tr", { key: item.id }, [
            _c("td", [_vm._v(_vm._s(item.id))]),
            _c("td", [_vm._v(_vm._s(item.company))]),
            _c("td", [_vm._v(_vm._s(item.equipment))]),
            _c("td", [_vm._v(_vm._s(item.name))]),
            _c("td", [_vm._v(_vm._s(item.phoneNumber))])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("序号")]),
        _c("th", [_vm._v("单位")]),
        _c("th", [_vm._v("设备")]),
        _c("th", [_vm._v("姓名")]),
        _c("th", [_vm._v("联系电话")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }